import Image from "next/image";
import React, { useEffect, useState } from "react";

type Props = {
  children: React.ReactNode;
};

export const MobileWrapper = ({ children }: Props) => {


  return (
    <div className="flex items-center justify-center">
      <div className="max-w-[430px] h-[100vh] w-full relative">
        {children}
      </div>
    </div>
  );
};
