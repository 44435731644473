import { SWRConfig } from "swr";
import type { AppProps } from "next/app";
import { useEffect, useLayoutEffect, useState } from "react";

import { handleAPIError, handleAPIRequest } from "@/utils/handleAPIError";
import localFont from "next/font/local";

import "../styles/global.css";
import "react-toastify/dist/ReactToastify.css";

import { MobileWrapper } from "@/template/MobileWrapper";
import { getCookie } from "cookies-next";
import { useAuthToken } from "@/hooks/useAuthToken";
import { MantineProvider } from "@mantine/core";
import { cssTransition, ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const pretendard = localFont({
  variable: "--pretendard",
  src: [
    {
      path: "../src/fonts/Pretendard-Regular.otf",
      weight: "300",
      style: "normal",
    },
    {
      path: "../src/fonts/Pretendard-Medium.otf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../src/fonts/Pretendard-SemiBold.otf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../src/fonts/Pretendard-Bold.otf",
      weight: "700",
      style: "normal",
    },
  ],
});

export default function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());

  useLayoutEffect(() => {
    handleAPIError();
    handleAPIRequest();
  }, []);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const token = getCookie("token");

  //     localStorage.setItem("accessToken", token as string);
  //   }
  // }, []);

  return (
    <div className="">
      <style jsx global>
        {`
          html {
            --pretendard: ${pretendard.style.fontFamily};
          }
        `}
      </style>
      <SWRConfig
        value={{
          suspense: false,
          revalidateOnFocus: false,
          dedupingInterval: 10000,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <MantineProvider>
            <MobileWrapper>
              <Component {...pageProps} />
            </MobileWrapper>
          </MantineProvider>
        </QueryClientProvider>
        <ToastContainer
          position="bottom-center"
          icon={false}
          hideProgressBar
          autoClose={3000}
          pauseOnFocusLoss={false}
          closeOnClick={false}
          limit={3}
          className={() =>
            "w-full fixed bottom-[50px] z-[10000] box-border p-4"
          }
          toastClassName={
            "rounded-12 shadow-popup text-white-85 relative mb-8 box-border flex cursor-pointer items-center justify-center"
          }
          bodyClassName={() =>
            "my-auto mx-0 flex flex-1 items-center [&_div]:flex [&_div]:items-center [&_div]:w-full"
          }
          containerId="root_container"
        />
        {/* INJECT GTM OR GA SCRIPT IN HERE. */}
      </SWRConfig>
    </div>
  );
}
